@use "@angular/material" as mat;

@import "@angular/cdk/overlay-prebuilt.css";
@import "mixins";
@import "typography";

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

// TODO: move palette to shared or another folder
$primary-palette: (
  50: #feeee3,
  100: #fcd5b9,
  200: #fbba8b,
  300: #f99e5c,
  400: #f78939,
  500: #f67416,
  600: #f56c13,
  700: #f36110,
  800: #f2570c,
  900: #ef4406,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text )
);

$warn-palette: (
  50: #fee8eb,
  100: #fcc5ce,
  200: #fa9fae,
  300: #f7788d,
  400: #f65b74,
  500: #f43e5c,
  600: #f33854,
  700: #f1304a,
  800: #ef2841,
  900: #ec1b30,
  A100: #ffffff,
  A200: #ffecee,
  A400: #ffb9bf,
  A700: #ffa0a8,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000)
);

// $my-primary: mat.define-palette($primary-palette, 500, 100, 700);
// $my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// $my-warn: mat.define-palette($warn-palette, 500, 100, 700);

// $my-theme: mat.define-light-theme(
//     (
//       color: (
//         primary: $my-primary,
//         accent: $my-accent,
//         warn: $my-warn,
//       ),
//       typography:
//       mat.define-typography-config(
//         $font-family: 'Lato',
//       ),
//       density: 0,
//     )
// );

// @include mat.all-component-themes($my-theme);

:root {
  --primary-light: #5eead4; // not yet
  --primary: #f47621;
  --primary-dark: #0f766e; // not yet
  --secondary-light: #bae6fd; // not yet
  --secondary: #0ea5e9; // not yet
  --secondary-dark: #0369a1; // not yet
  --blue: #586770;
  --blue-dark: #333f48;
  --white: #ffffff;
  --black: #000000;
  --text-02: #525252;
  --text-03: #a8a8a8;
  --field-01: #f4f4f4;

  --brand-active: #f67416;
  --brand-background: #fff6eb;
  --brand-destructive-active: #e21d48;
  --brand-selected: #fdba72;
  --brand-success: #66a50d;
  --brand-destructive-hover: #f43e5c;

  --mode-base-00: #fff;
  --mode-base-50: #fcfcfc;
  --mode-base-100: #f4f4f5;
  --mode-base-200: #e4e4e7;
  --mode-base-400: #a1a1aa;
  --mode-base-600: #52525b;

  --mode-alpha-100: rgba(173, 173, 173, 0.2);
  --mode-alpha-200: rgba(50, 62, 72, 0.2);
  --mode-alpha-400: #323e4866;
  --mode-alpha-600: #52525b;
  --mode-alpha-700: #323e48b2;
  --mode-alpha-900: #323e48;

  --colors-base-00: #fff;
  --colors-base-900: #323e48;

  --colors-orange-50: #fff6eb;
  --colors-orange-500: #f67416;
  --colors-orange-200: #fed6a9;

  --colors-gray-900: #202024;

  --colors-blue-gray-200: #e1e7ef;
  --colors-blue-gray-400: #94a3b8;

  --space-3: 4px;
  --space-4: 8px;
  --space-5: 10px;
  --space-6: 12px;
  --space-7: 16px;
  --space-8: 20px;
  --space-9: 24px;
  --space-11: 32px;
  --space-13: 40px;
  --space-15: 48px;
  --space-16: 56px;
  --space-17: 64px;
  --space-18: 80px;
  --space-24: 160px;

  --radius-radi-4: 8px;
  --radius-radi-6: 12px;
  --radius-radi-8: 22px;

  --md: 640px;

  --income-border: #bedbfe;
  --income-background-color: #f1f5f9;

  --expenses-border: #e4e4e7;
  --expenses-background-color: #f4f4f5;

  --liabilities-border: #fbd0e8;
  --liabilities-background-color: #fce8f4;

  --assets-border: #d9f99f;
  --assets-background-color: #ebfcca;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  height: 100%;
}

ul {
  list-style-type: none;
}

a {
  color: var(--mode-alpha-900, #323e48);
  font-weight: 500;
  text-underline-offset: 0.25em;
}

a.secondary {
  color: var(--secondary-dark, #0369a1);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.button-content-wrapper {
  display: flex;
  align-items: center;
  gap: var(--space-6, 12px);
  padding: calc(var(--space-4) - 1px) 0;

  >span {
    text-align: left;
    @include text-lg(semibold);
  }
}

svg-icon-sprite {
  .primary-color {
    color: var(--brand-active);
  }

  .success-color {
    color: var(--brand-success);
  }

  .error-color {
    color: var(--brand-destructive-active);
  }
}

mat-card {
  border-width: 1px;
  border-color: #e4e4e7;

  &.mat-mdc-card {
    --mdc-elevated-card-container-shape: var(--radius-radi-6, 12px);
    --mdc-outlined-card-container-shape: var(--radius-radi-6, 12px);

    --mdc-elevated-card-container-elevation: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }

  .mat-mdc-card-content {
    padding: var(--space-11, 32px);

    &:last-child {
      padding-bottom: var(--space-11, 32px);
    }

    &:first-child {
      padding-top: var(--space-11, 32px);
    }
  }

  &.mat-card--small {
    .mat-mdc-card-content {
      padding: var(--space-7);

      &:last-child {
        padding-bottom: var(--space-9);
      }

      &:first-child {
        padding-top: var(--space-9);
      }
    }
  }
}

::ng-deep .text-white {
  color: white !important;
}

.text-bold {
  font-weight: 900;
}

.startegy-state h1.head-main {
  font-family: Gotham !important;
  font-size: 36px !important;
  font-weight: 300 !important;
  line-height: 50px !important;
  color: #323E48 !important;
}

.startegy-state h1.head-main.lite-head {
  color: rgba(50, 62, 72, 0.7) !important;
}

.quote-image .footer-img footer.footer .footer-right p {
  color: #fff !important;
}

.customer-main footer.footer .footer-content .footer-left p {
  color: var(--mode-alpha-700, #323E48B2) !important;
  background-color: #fff !important;
}

.startegy-quote footer.footer .footer-content .footer-left p {
  color: rgba(50, 62, 72, 0.7) !important;
}

.customer-main footer.footer .footer-content .footer-left {
  padding-left: 20px;
}

.customer-main footer.footer .footer-content .footer-left img.footer-logo {
  display: none;
}

.quote-image img.footer-logo {
  filter: brightness(0) invert(1);
}

.startegy-state h1.head-mid {
  font-family: Gotham !important;
  font-size: 32px !important;
  font-weight: 300 !important;
  line-height: 40px !important;
  color: #323E48 !important;
}

p,
li {
  color: #323E48 !important;
  font-family: Lato !important;
}

body {
  width: 1440px;
  margin: auto;
}

@page {
  size: A4;
  margin: 0;
}


@media print {
  .startegy-state h1.head-main {
    font-family: Gotham !important;
    font-size: 40px !important;
    line-height: 45px !important;
    color: #323E48 !important;
  }

  .startegy-state h1.head-mid {
    font-family: Gotham !important;
    font-size: 30px !important;
    line-height: 33px !important;
    color: #323E48 !important;
  }

  .footer-content {
    flex-direction: row !important;
  }

  .disc-main-foot {
    padding: 0 40px !important;
  }

  ::ng-deep .footer-gap {
    padding: 0 40px !important;
  }

  .footer-cus .footer-content {
    padding: 0 40px !important;
  }

}